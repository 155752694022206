<script lang="ts">
import type { Campaign } from '@/campaign/types';
import type { PropType } from 'vue';
import type { AudienceWithSegments } from '@/audience/types';
import type { Button, EmitCallback } from '@/survey/components/publish/campaign/popup/views/common/SelectableButtonList.vue';

import { ref, computed, defineComponent, toRefs } from 'vue';

import i18next from 'i18next';
import SelectableButtonList from '@/survey/components/publish/campaign/popup/views/common/SelectableButtonList.vue';
import { useCampaigns } from '@/campaign';

export default defineComponent({
    name: 'RecipientsDefault',

    components: { SelectableButtonList,  },

    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },

    props: {
        modelValue: {
            type: String,
            required: true,
        },

        campaign: {
            type: Object as PropType<Campaign>,
            required: true,
        },

        audiences: {
            type: Array as PropType<AudienceWithSegments[]>,
            required: true,
        },
    },

    emits: ['show-segment-list', 'show-next', 'update:modelValue'],

    setup: (props, { emit }) => {
        // create targetButtons when component is mounted and not when imported (i18next might not be initialized in the later case)
        const targetButtons: Button[] = [
            {
                icon: 'users',
                title: i18next.t('DATA_SOURCE_TYPE.AUDIENCE', 'Audience'),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_RECIPIENTS_AUDIENCE_DESCRIPTION', 'Send a general survey to your audience for a broader recipient feedback.'),
                footnote: i18next.t('AUDIENCE.CAMPAIGN_POPUP_RECIPIENTS_AUDIENCE_FOOTNOTES', 'Please note, the audience\'s recipients represent the state of the selected audience in the moment of sending the campaign.'),
                action: (emit: EmitCallback) => emit('show-audience-list'),

                isDisabled: (campaign, audiences) => {
                    return !campaign.name
                || !audiences.length;
                },
            },
            {
                icon: 'user',
                title: i18next.t('AUDIENCE.RECIPIENT_SEGMENT', 'Segment'),
                summary: i18next.t('AUDIENCE.CAMPAIGN_POPUP_RECIPIENTS_SEGMENT_DESCRIPTION', 'Using segments enable you to receive more representative responses from a focused group of people.'),
                footnote: i18next.t('AUDIENCE.CAMPAIGN_POPUP_RECIPIENTS_SEGMENT_FOOTNOTES', 'Please note, the segment\'s recipients represent the state of the selected segment in the moment of sending the campaign.'),
                action: (emit: EmitCallback) => emit('show-segment-list'),

                isDisabled: (campaign, audiences) => {
                    return !campaign.name
                || !audiences.length
                || !audiences.find(audience => !!audience.segments.length);
                },
            },
        ];

        const { isPopupViewOnly } = toRefs(useCampaigns().state);
        const selectedButtonIndex = ref(props.campaign.audienceId && props.campaign.segmentId ? 1 : (props.campaign.audienceId ? 0 : -1));
        const selectedButton = computed(() => targetButtons[selectedButtonIndex.value]);

        return {
            targetButtons,
            selectedButton,
            selectedButtonIndex,
            isPopupViewOnly,
            // @ts-ignore
            callAction: (button: Button) => button.action(emit),

            selectButton: (index: number) => {
                selectedButtonIndex.value = index;
            },
        };
    },
});
</script>
<template>
    <div class="flex flex-col">
        <span>{{ $t('AUDIENCE.CAMPAIGN_POPUP_CAMPAIGN_NAME_INPUT', 'Campaign name') }}</span>
        <z-input
            :model-value="modelValue"
            :disabled="isPopupViewOnly"
            :placeholder="$t('AUDIENCE.CAMPAIGN_POPUP_CAMPAIGN_NAME_INPUT', 'Campaign name')"
            @update:modelValue="$emit('update:modelValue', $event)"
        />

        <main class="flex-grow flex flex-col items-center mt-4">
            <h1 class="mx-2 text-xl font-bold self-start">
                {{ $t('AUDIENCE.CAMPAIGN_POPUP_SELECT_TARGET_HEADER', 'Select your target audience') }}
            </h1>
            <selectable-button-list
                :campaign="campaign"
                :audiences="audiences"
                :buttons="targetButtons"
                :disabled="!modelValue || isPopupViewOnly"
                :selected-button-index="selectedButtonIndex"
                @select-index="selectButton"
            />
            <div class="flex-grow mt-4 flex items-end">
                <z-button
                    :disabled="!modelValue || selectedButtonIndex === -1 || isPopupViewOnly"
                    @click="callAction(selectedButton)"
                >
                    {{ $t('BUTTONS.CONTINUE', 'Continue') }}
                </z-button>
            </div>
        </main>
    </div>
</template>
