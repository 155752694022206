<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useNamespacedState } from 'vuex-composition-helpers';

import SelectableButton from '@/survey/components/publish/campaign/popup/views/common/SelectableButton.vue';
import ZIcon from '@/components/ui/Icon.vue';
import ZButton from '@/common/components/design-system/Button.vue';

import { useCampaigns } from '@/campaign';
import { fetchCampaignsByDatasetId } from '@/campaign/api';
import { Campaign, Wave } from '@/campaign/types';
import { AudienceWithSegments } from '@/audience/types';

import wait from '@/utils/wait';
import { setLoading } from '@/utils/loading';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';

import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import useSubscriptionStore from '@/subscription/store';
import dayjs from 'dayjs';
import useAudienceStore from '@/audience/store';

import { ContentLoader } from 'vue-content-loader';
import ScheduledSendingButton from './Buttons/ScheduledSendingButton.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import globals from '@/utils/globals';

export default defineComponent({
    name: 'SendingDefault',
    components: {
        SelectableButton,
        ZIcon,
        ZButton,
        ContentLoader,
        ScheduledSendingButton,
        Scrollbar,
    },
    props: {
        campaign: { type: Object as () => Campaign, required: true },
        audiences: { type: Array as () => AudienceWithSegments[], required: true },
        isReminder: { type:Boolean, default: false },
    },
    emits: ['show-test-email-settings', 'show-prev', 'close', 'show-api-settings', 'set-schedule', 'campaign-saved'],
    setup: (props, { emit }) => {
        const { datasetId } = useNamespacedState('survey', ['datasetId']);
        const { sendCampaign, sendCampaignReminder } = useCampaigns();
        const { countAudienceMembers } = useAudienceStore().actions;
        const { canUseFeature } = useSubscriptionStore().getters;

        const fetchSendingStatus = async () => {
            const sentCampaign = (await fetchCampaignsByDatasetId(datasetId.value)).find(c => c.id == props.campaign.id)!;

            sentRecipientCount.value = (sentCampaign.waves?.at(-1) as Wave).sentRecipientCount;

            return sentCampaign.sendingInProgress;
        };

        const sending = ref(false);
        const sent = ref(false);
        const sentRecipientCount = ref(0);
        const recipientCount = computed(() => {
            if (props.campaign.sendOnlyNewMembers) {
                return recipientCountSinceLastSending.value;
            }
            if (props.campaign.segmentId) {
                return props.audiences
                    .find(audience => audience.uid === props.campaign.audienceId)
                    ?.segments.find(segment => segment.id === props.campaign.segmentId)
                    ?.memberCount || 0;
            }
            else {
                return props.audiences.find(audience => audience.uid === props.campaign.audienceId)?.members || 0;
            }

        });
        const recipientCountSinceLastSending = ref(0);

        const emailQuotaNeeded = computed(() => {
            if (props.isReminder) {
                return useCampaigns().state.reminderRecipientCount;
            }
            return props.campaign.sendOnlyNewMembers && props.campaign.lastSentAt ? recipientCountSinceLastSending.value : recipientCount.value;
        });
        const hasEnoughEmailQuota = computed(() => canUseFeature.value(planFeatureTypeEnum.CAMPAIGN_EMAILS, emailQuotaNeeded.value) || globals.quotaHandlingLimited());

        const sendNow = async () => {
            setLoading(true);
            try {
                const campaign = await sendCampaign(props.campaign, recipientCount.value);
                emit('campaign-saved', campaign);
                setLoading(false);
                toaster.success(i18next.t('AUDIENCE.CAMPAIGN_POPUP_EMAILS_SENT_INFO', 'Campaign sending started. You will get an email notification when finishes.'));

                sending.value = true;

                while (sending.value) {
                    await wait(1);
                    sending.value = await fetchSendingStatus();
                }

                sent.value = true;

            }
            catch (e) {
                Sentry.captureException(e);
                setLoading(false);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
        };

        const sendReminder = async () => {
            setLoading(true);
            try {
                const reminderWave = useCampaigns().state.reminderWave;
                await sendCampaignReminder(props.campaign, reminderWave!.waveId, reminderWave!.reminderType!);

                setLoading(false);
                toaster.success(i18next.t('AUDIENCE.CAMPAIGN_POPUP_EMAILS_SENT_INFO', 'Campaign sending started. You will get an email notification when finishes.'));

                sending.value = true;

                while (sending.value) {
                    await wait(1);
                    sending.value = await fetchSendingStatus();
                }

                sent.value = true;
            }
            catch (e) {
                Sentry.captureException(e);
                setLoading(false);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
        };

        const isApiAccessEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.API_ACCESS));

        const loadingMembersCount = ref(false);

        onMounted(async () => {
            if (props.campaign.sendOnlyNewMembers && props.campaign.lastSentAt) {
                try {
                    loadingMembersCount.value = true;
                    recipientCountSinceLastSending.value =  await countAudienceMembers(props.campaign.audienceId, props.campaign.segmentId, dayjs(props.campaign.lastSentAt).unix());
                } catch (e) {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                    Sentry.captureException(e);
                }
                finally {
                    loadingMembersCount.value = false;
                }
            }
        });

        return {
            sendNow,
            sendReminder,
            sending,
            sent,
            sentRecipientCount,
            recipientCount,
            isApiAccessEnabled,
            hasEnoughEmailQuota,
            loadingMembersCount,
            emailQuotaNeeded
        };
    },
});
</script>
<template>
    <main class="flex flex-col">
        <Scrollbar class="flex-grow flex">
            <div
                class="grid gap-4 m-auto"
                :class="{
                    'grid-cols-2': isApiAccessEnabled && !isReminder,
                    'grid-cols-3': !isApiAccessEnabled || isReminder,
                }"
                style="width: max-content"
            >
                <selectable-button>
                    <z-icon
                        class="text-5xl text-primary-700"
                        icon="envelope-circle-check"
                        type="fat"
                    />
                    <h1 class="font-bold">
                        {{ $t('AUDIENCE.SEND_TEST_EMAIL', 'Send Test Email') }}
                    </h1>
                    <div class="flex-grow text-default text-left">
                        {{ $t('AUDIENCE.SEND_TEST_EMAIL_SUMMARY', 'Preview your invitation email\'s appearance across various email platforms by sending a test email.') }}
                    </div>
                    <z-button
                        @click.stop="$emit('show-test-email-settings')"
                    >
                        {{ $t('AUDIENCE.SEND_TEST_EMAIL_BUTTON', 'Add recipients & Test') }}
                    </z-button>
                </selectable-button>
                <selectable-button
                    :warning="!hasEnoughEmailQuota && !loadingMembersCount"
                    :disabled="loadingMembersCount"
                >
                    <template v-if="loadingMembersCount">
                        <content-loader
                            preserve-aspect-ratio="none"
                            class="h-full w-full"
                        />
                    </template>
                    <template v-else>
                        <z-icon
                            class="text-5xl"
                            :class="{
                                'text-yellow-500': !hasEnoughEmailQuota,
                                'text-primary-700': hasEnoughEmailQuota
                            }"
                            icon="paper-plane-top"
                            type="fat"
                        />
                        <h1 class="font-bold">
                            {{ isReminder ? $t('AUDIENCE.SEND_REMIDNER', 'Send Reminder now') : $t('AUDIENCE.SEND_NOW', 'Launch campaign now') }}
                        </h1>
                        <div
                            v-if="hasEnoughEmailQuota"
                            class="flex-grow text-default text-left"
                        >
                            {{ isReminder ? $t('AUDIENCE.SEND_REMINDER_SUMMARY', 'Send your reminder right away to chosen recipients! Consider sending a test email first to ensure everything looks perfect.') : $t('AUDIENCE.SEND_NOW_SUMMARY', 'Send your campaign right away to chosen recipients! Consider sending a test email first to ensure everything looks perfect.') }}
                        </div>
                        <div
                            v-else
                            class="flex-grow text-default text-left"
                        >
                            {{ $t('AUDIENCE.INSUFFICIENT_QUOTA_WARNING_TEXT', 'Insufficient quota to send this campaign to the selected recipients. Please contact your account manager to purchase additional quota.') }}
                        </div>
                        <div class="relative">
                            <z-button
                                :loading="sending"
                                :disabled="!hasEnoughEmailQuota"
                                @click.stop="isReminder ? sendReminder() : sendNow()"
                            >
                                {{ sent ? $t('AUDIENCE.RESEND_BUTTON', 'Resend') : $t('AUDIENCE.SEND_NOW_BUTTON', 'Send Now') }}
                            </z-button>
                            <div
                                v-if="sending || sent"
                                class="text-sm absolute text-neutral-500"
                                :style="{
                                    'width': 'max-content',
                                    'left': '50%',
                                    'bottom': 'calc(100% + 0.25em)',
                                    'transform': 'translateX(-50%)',
                                }"
                            >
                                <span v-if="sending">
                                    {{ $t('AUDIENCE.SENDING', {
                                        defaultValue: 'Sending \{\{sent\}\}/\{\{all\}\}',
                                        sent: sentRecipientCount,
                                        all: emailQuotaNeeded
                                    }) }}
                                </span>
                                <span v-else>
                                    {{ $t('AUDIENCE.SENDING_FINISHED', 'Sending finished') }}
                                </span>
                            </div>
                        </div>
                    </template>
                </selectable-button>
                <selectable-button
                    v-if="isApiAccessEnabled && !isReminder"
                    :warning="!hasEnoughEmailQuota && !loadingMembersCount"
                    :disabled="loadingMembersCount"
                >
                    <template v-if="loadingMembersCount">
                        <content-loader
                            preserve-aspect-ratio="none"
                            class="h-full w-full"
                        />
                    </template>
                    <template v-else>
                        <z-icon
                            class="text-5xl"
                            :class="{
                                'text-yellow-500': !hasEnoughEmailQuota,
                                'text-primary-700': hasEnoughEmailQuota
                            }"
                            icon="code-branch"
                            type="fat"
                        />
                        <h1 class="font-bold">
                            {{ $t('AUDIENCE.TRIGGER_API', 'Trigger campaign via API') }}
                        </h1>
                        <div
                            v-if="hasEnoughEmailQuota"
                            class="flex-grow text-default text-left"
                        >
                            {{ $t('AUDIENCE.TRIGGER_API_SUMMARY', 'Trigger your email campaign via a simple API call, using the designated endpoint.') }}
                        </div>
                        <div
                            v-else
                            class="flex-grow text-default text-left"
                        >
                            {{ $t('AUDIENCE.INSUFFICIENT_QUOTA_WARNING_TEXT', 'Insufficient quota to send this campaign to the selected recipients. Please contact your account manager to purchase additional quota.') }}
                        </div>
                        <z-button
                            :disabled="!hasEnoughEmailQuota"
                            @click="$emit('show-api-settings')"
                        >
                            {{ $t('AUDIENCE.TRIGGER_API_BUTTON', 'Show API Details') }}
                        </z-button>
                    </template>
                </selectable-button>
                <ScheduledSendingButton
                    :hasEnoughEmailQuota="hasEnoughEmailQuota"
                    :isWaveReminder="isReminder"
                    @set-schedule="$emit('set-schedule', $event)"
                />
            </div>
        </Scrollbar>
        <section class="flex flex-row mx-auto mt-4 space-x-2">
            <z-button
                variant="outline"
                @click="$emit('show-prev')"
            >
                {{ $t('AUDIENCE.BUTTON_BACK', 'Go back') }}
            </z-button>
            <z-button
                v-if="!isApiAccessEnabled && !isReminder"
                @click="$emit('close')"
            >
                {{ $t('AUDIENCE.BUTTON_KEEP_AS_DRAFT_AND_CLOSE', 'Keep as draft and close') }}
            </z-button>
        </section>
    </main>
</template>
