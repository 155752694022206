<script lang="ts">
import type { Component } from 'vue';
import type { Campaign } from '@/campaign/types';
import type { AudienceWithSegments } from '@/audience/types';
import { isEqual } from 'lodash';
import { toaster } from '@/utils/toaster';
import { useCampaigns } from '@/campaign';
import { useSurveyEmailTemplates } from '@/survey/api';
import { ref, watch, computed, defineComponent, PropType, onUnmounted } from 'vue';

import i18next from 'i18next';
import Popup from '@/components/ui/Popup.vue';
import SurveyStore from '@/survey/store/survey';
import ConfirmPopup from '@/utils/confirmPopup';
import PopupTabViewSending from '@/survey/components/publish/campaign/popup/views/Sending.vue';
import PopupTabViewRecipients from '@/survey/components/publish/campaign/popup/views/Recipients.vue';
import PopupTabViewEmailCustomization from '@/survey/components/publish/campaign/popup/views/EmailCustomization.vue';
import PopupTabViewTemplateCustomization from '@/survey/components/publish/campaign/popup/views/TemplateCustomization.vue';
import PopupTabViewVariablesAndUrlParams from '@/survey/components/publish/campaign/popup/views/VariablesAndUrlParams.vue';
import PopupTabViewSummary from './views/summary/Summary.vue';
import PopupTabViewScheduledSending from '@/survey/components/publish/campaign/popup/views/ScheduledSending.vue';
import { useRoute } from 'vue-router/composables';

const confirmCloseWithoutSaving = (hasPopupStateBeenSaved: boolean): Promise<boolean> => {
    if (!hasPopupStateBeenSaved) {
        return ConfirmPopup.destruction(
            i18next.t('GLOBAL.UNSAVED_CHANGES', 'Unsaved changes'),
            i18next.t('AUDIENCE.CAMPAIGN_CLOSE_WITHOUT_SAVE_DESCRIPTION', 'You have unsaved changes. Are you sure you would like to leave?'),
            i18next.t('HOME.ONBOARDING.BUTTON_CLOSE', 'Close'),
            i18next.t('GLOBAL.CANCEL', 'Cancel')
        ) as Promise<boolean>;
    }

    return Promise.resolve(true);
};

async function onBeforeClose (this: Tab, hasPopupStateBeenSaved: boolean, closePopup: () => void) {
    if (await confirmCloseWithoutSaving(hasPopupStateBeenSaved)) {
        this.props = null;
        closePopup();
    }
}

const confirmUpdateOnRecipientChange = (): Promise<boolean> => {
    return ConfirmPopup.destruction(
        i18next.t('AUDIENCE.RECIPIENT_CHANGE_DETECTED', 'Changes to Recipients Detected'),
        i18next.t('AUDIENCE.RECIPIENT_CHANGE_DETECTED_DESCRIPTION', 'The recipients of this campaign has been modified. As a result, the setup for variables and URL parameters has been reset. This may lead to missing variable data fields in your email template.\n' +
            'Please review and set up the variables and URL parameters again under Variables and URL Parameters. Additionally, we recommend double-checking the variables embedded in your email template.'),
        i18next.t('GLOBAL.OK_NOTED', 'Ok, Noted'),
        i18next.t('GLOBAL.CANCEL', 'Cancel')
    ) as Promise<boolean>;
};


/* eslint-disable */
// NOTE(adam): EsLint does not understand TypeScript types - complains about unused arguments.
interface Tab {
    title: string;
    props: null | Record<string, unknown>;
    component: Component;
    shouldBeSkipped (): boolean;
    precondition (campaign: Campaign, reminderTypeNotSaved?: boolean): boolean;
    onBeforeClose (hasPopupStateBeenSaved: boolean, closePopup: () => void): void | Promise<void>;
    hide?: boolean,
    isSchedule?: boolean,
}

export enum TabIndex {
    Recipients,
    VariablesAndUrlParams,
    TemplateCustomization,
    EmailCustomization,
    Summary,
    Sending,
    Schedule,
}

/* eslint-enable */

const tabs: Tab[] = [
    // tab[0]
    {
        onBeforeClose,
        props: null,
        shouldBeSkipped: () => false,
        precondition: () => true,
        component: PopupTabViewRecipients,
        title: i18next.t('AUDIENCE.POPUP_TAB_RECIPIENTS', 'Recipients'),
    },
    // tab[1]
    {
        onBeforeClose,
        props: null,
        component: PopupTabViewVariablesAndUrlParams,
        title: i18next.t('AUDIENCE.POPUP_TAB_VARIABLES', 'Variables and URL Parameters'),
        precondition (campaign) {
            if (tabs[0].precondition(campaign)) {
                return !!campaign.name
                    && !!campaign.audienceId;
            }

            return false;
        },

        // NOTE(adam): This is somewhat inelegant, but so is the only remaining
        // vuex store in the project :)
        shouldBeSkipped: () => {
            // Don't count audience-related default url parameters, only user-defined ones
            return !SurveyStore.state.urlParameters.length;
        }
    },
    // tab[2]
    {
        onBeforeClose,
        props: null,
        component: PopupTabViewTemplateCustomization,
        title: i18next.t('AUDIENCE.POPUP_TAB_TEMPLATE_SELECT', 'Template Selection'),
        precondition: (campaign) => {
            if (tabs[1].precondition(campaign)) {
                return true;
            }

            return false;
        },
        shouldBeSkipped: () => {
            // NOTE(adam): This is not correct, because it can happen that
            // templates are not (yet) loaded. But since these hooks are
            // synchronous (and shall remain that way), we don't really have a
            // choice but to rely on chance.
            //
            // As a best effort, we call to load templates all the way back in
            // the CampaignEditor.
            return !useSurveyEmailTemplates().state.surveyEmailTemplates.length;
        },
    },
    // tab[3]
    {
        onBeforeClose,
        props: null,
        component: PopupTabViewEmailCustomization,
        title: i18next.t('AUDIENCE.POPUP_TAB_EMAIL', 'Email Customization'),
        precondition: (campaign) => {
            if (tabs[2].precondition(campaign)) {
                return !!campaign.emailTemplateId || true; //default template can be chosen or template chooser step can even be skipped
            }

            return false;
        },
        shouldBeSkipped: () => false,
    },
    // tab[4]
    {
        onBeforeClose,
        props: null,
        component: PopupTabViewSummary,
        title: i18next.t('AUDIENCE.POPUP_TAB_SUMMARY', 'Summary'),
        precondition: (campaign) => {
            if (tabs[3].precondition(campaign)) {
                // Campaign should be saved at this point
                return !!campaign.id;
            }

            return false;
        },
        shouldBeSkipped: () => false
    },
    // tab[5]
    {
        onBeforeClose (_: boolean, closePopup: () => void) {
            this.props = null;
            closePopup();
        },
        props: null,
        component: PopupTabViewSending,
        title: i18next.t('AUDIENCE.POPUP_TAB_SENDING', 'Sending'),
        precondition: (campaign) => {
            if (tabs[3].precondition(campaign)) {
                // Campaign should be saved at this point
                return !!campaign.id;
            }

            return false;
        },
        shouldBeSkipped: () => {
            return useCampaigns().state.isPopupViewOnly;
        }
    },
    // tab[6]
    {
        onBeforeClose,
        props: null,
        component: PopupTabViewScheduledSending,
        title: i18next.t('AUDIENCE.SCHEDULE', 'Schedule'),
        precondition: () => {
            return true;
        },
        shouldBeSkipped: () => false,
        isSchedule: true,
    },
];

function onlyNotSkipped (tab: Tab) {
    return !tab.shouldBeSkipped();
}

const WATCH_IMMEDIATE = { immediate: true };

export default defineComponent({
    name: 'CreateNewCampaignPopup',

    components: { Popup },

    props: {
        isOpen: { type: Boolean, default: false },
        selectedTabIndex: { type: Number, default: 0 },
        audiences: { type: Array as PropType<AudienceWithSegments[]>, required: true, },
        campaignToEdit: {
            type: Object as PropType<Campaign | null>,
            required: false,
            default: null,
        },
        isEdit: { type: Boolean, default: false },
        isReminder: { type: Boolean, default: false },
        isRecurring: { type: Boolean, default: undefined },
    },

    setup (props, ctx) {
        const route = useRoute();
        const hasPopupStateBeenSaved = computed( () => isEqual(props.campaignToEdit ? props.campaignToEdit : initEmptyCampaign(route.params.id), campaign.value));

        const { initEmptyCampaign, resetPopupState } = useCampaigns();

        const campaign = ref(props.campaignToEdit ?? initEmptyCampaign(route.params.id));
        const goBackButtonClicked = ref(false);

        const emitCloseEvent = () => ctx.emit('close-popup');
        const currentTabIndex = ref(props.selectedTabIndex);

        // NOTE(adam): Is there a need to make this reactive?
        // Note(jani): now there is
        const showSchedule = ref(props.selectedTabIndex == TabIndex.Schedule);
        const notSkippedTabs = computed(() => tabs.filter(t => !t.hide).filter(onlyNotSkipped).filter(tab => !tab.isSchedule || showSchedule.value));

        watch(() => notSkippedTabs.value.length, (currentLength, prevLength) => {
            if (
                // this happens when we are on the email template customization tab and there were no templates before
                // after saving the template the template selection tab becomes visible and it would be the current tab
                // so to stay on the right tab we need to increase the currentTabIndex
                // before the fix the email customization component emited the 'campaign-saved' and 'show-next' events but since
                // it wasn't anymore the child of this component, the event listeners wouldn't fire for them
                // and update the campaignToEdit prop
                currentLength > prevLength
                // the only other time when the length of notSkippedTabs changes is when go to the schedule page
                // so we need to check if we are not on the sending tab (last tab at the moment)
                && currentTabIndex.value < currentLength - 1
            ) {
                currentTabIndex.value++;
            }
            // yes this is an ugly solution and i will deny ever writing this :D
            // TODO: i think we shouldn't filter the tabs but check at tab change whether skip a tab or not
        });

        const checkForAudienceChange = async () => {
            // props.campaignToEdit is the latest saved version, campaign.value the latest updated version
            if (
                !!props.campaignToEdit && !!props.campaignToEdit!.audienceId &&
                props.campaignToEdit!.audienceId !== campaign.value.audienceId &&
                !!props.campaignToEdit!.variables && !!campaign.value.variables &&
                goBackButtonClicked.value === false
            ) {
                if (await confirmUpdateOnRecipientChange()) {
                    // reset variables
                    campaign.value = {
                        ...campaign.value,
                        variables: null
                    };
                } else {
                    // reset recipients
                    campaign.value = {
                        ...campaign.value,
                        audienceId: props.campaignToEdit!.audienceId,
                        segmentId: props.campaignToEdit!.segmentId
                    };
                }
            }
        };

        const currentPopupTab = computed(() => notSkippedTabs.value[currentTabIndex.value]);

        const changeTab = async (requestedIndex: number) => {
            if (requestedIndex == currentTabIndex.value) return;
            // if moving away from the schedule tab
            if (currentPopupTab.value?.isSchedule) showSchedule.value = false;

            let actualIndex = requestedIndex;
            let campaignModel = campaign.value;

            for (let tab = notSkippedTabs.value[actualIndex];
                -1 < actualIndex && !tab.precondition(campaignModel);
                tab = notSkippedTabs.value[--actualIndex]);

            if (actualIndex < requestedIndex) {
                toaster.error(i18next.t('AUDIENCE.POPUP_TAB_NAV_ERROR', 'You can only proceed to the subsequent tabs after completing all requirements!'));
            }

            // In Recipients goBackButtonClicked prop ensures that default page can show even if there's only 1 audience and/or segment
            // Set to true if target tab (actual index) is smaller than current tab index
            goBackButtonClicked.value = currentTabIndex.value > actualIndex;

            // warning should be thrown before changing tabs in case variable reset is needed
            // only check if user is leaving Recipients
            if (currentTabIndex.value === 0) {
                await checkForAudienceChange();
            }

            currentTabIndex.value = Math.max(0, actualIndex);
        };

        watch(() => props.selectedTabIndex, function remapTabIndexToNotSkippedTabs (requestedIndex: number) {
            const tab = tabs[requestedIndex];
            const notSkippedTabIndex = notSkippedTabs.value.indexOf(tab);
            if (-1 === notSkippedTabIndex) {
                throw new Error(`Cannot jump to skipped tab "${ tab.title }"!`);
            }

            return changeTab(notSkippedTabIndex);
        }, WATCH_IMMEDIATE);

        onUnmounted(() => {
            resetPopupState();
        });

        const reminderWave = computed(() => useCampaigns().state.reminderWave);
        const popupTitle = computed(() => {
            if (props.isReminder && reminderWave.value) {
                return i18next.t('AUDIENCE.WAVE', 'Wave') + ' ' + (reminderWave.value.index + 1) + ' ' + i18next.t('AUDIENCE.WAVE_REMINDER', 'Reminder');
            }
            return campaign.value.name || i18next.t('AUDIENCE.CAMPAIGN_POPUP_TITLE', 'New Campaign');
        });

        const reminderTypeNotSaved = computed<boolean>(() => props.isReminder && !reminderWave.value?.reminderType);

        return {
            campaign,
            notSkippedTabs,
            currentTabIndex,
            currentPopupTab,
            hasPopupStateBeenSaved,
            goBackButtonClicked,
            changeTab,
            popupTitle,
            reminderTypeNotSaved,
            reminderWave,
            closePopup: () => {
                notSkippedTabs.value[currentTabIndex.value].onBeforeClose(hasPopupStateBeenSaved.value, emitCloseEvent);
            },

            showNext: (props: any = null) => {
                let nextTab = currentTabIndex.value + 1;
                if (nextTab < notSkippedTabs.value.length) {
                    notSkippedTabs.value[nextTab].props = props;
                    return changeTab(nextTab);
                }
            },

            showPrev: (props = null) => {
                let prevTab = currentTabIndex.value - 1;
                if (-1 < prevTab) {
                    notSkippedTabs.value[prevTab].props = props;
                    return changeTab(prevTab);
                }
            },

            isTabDisabled: (index: number) => {
                return !notSkippedTabs.value[index].precondition(campaign.value);
            },

            updateCampaign: (updatedCampaign: Campaign) => {
                campaign.value = updatedCampaign;

            },
            campaignSaved: (savedCampaign: Campaign) => {
                campaign.value = savedCampaign;
                ctx.emit('campaign-saved', campaign.value);
            },
            enableSchedule() {
                showSchedule.value = true;
            },
        };
    }
});
</script>
<template>
    <popup
        is-closable
        has-backdrop
        width-class="w-5xl"
        :title="popupTitle"
        @close="closePopup"
    >
        <div
            class="flex flex-col"
            style="height: 80vh"
        >
            <section class="relative flex flex-row items-top justify-between mb-3">
                <hr class="absolute mt-6 left-0 right-0 mx-16 h-2px border-0 bg-neutral-400">
                <div class="relative flex flex-row items-top justify-between mb-3 w-full">
                    <button
                        v-for="(tab, index) in notSkippedTabs"
                        :key="index"
                        class="flex flex-col flex-shrink-0 items-center w-32 h-full outline-none disabled:pointer-events-none disabled:text-neutral-800"
                        :disabled="isTabDisabled(index) || (index != 0 && reminderTypeNotSaved)"
                        @click="changeTab(index)"
                    >
                        <figure
                            class="flex items-center justify-center w-8 h-8 rounded-full border-8 border-white box-content"
                            :class="
                                (index === currentTabIndex)
                                    ? 'bg-primary-700 text-white'
                                    : 'bg-neutral-400 text-neutral-1100'
                            "
                        >
                            {{ index + 1 }}
                        </figure>
                        <span class="block text-xs">
                            {{ tab.title }}
                        </span>
                    </button>
                </div>
            </section>
            <component
                :is="currentPopupTab.component"
                v-model="campaign"
                v-bind="currentPopupTab.props"
                :audiences="audiences"
                :go-back-button-clicked="goBackButtonClicked"
                :is-edit="isEdit"
                :is-reminder="isReminder"
                :reminderWave="reminderWave"
                :isRecurring="isRecurring || (currentPopupTab.props ? currentPopupTab.props.isRecurring == true : false)"
                class="flex-grow flex-shrink h-1"
                @update-campaign="updateCampaign"
                @show-next="showNext"
                @show-prev="showPrev"
                @close="closePopup"
                @set-go-back-to-default="goBackButtonClicked = false"
                @campaign-saved="campaignSaved"
                @set-schedule="enableSchedule(); showNext({ isRecurring: $event })"
            />
        </div>
    </popup>
</template>
